<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
  >
    <v-container class="mx-auto">
      <v-row>
        <!-- Company -->
        <v-col
          cols="12"
          sm="4"
        >
          <base-info-card :title="$t('Footer.rowTitleCompany')" />
          <v-row>
            <base-img
              :src="require('@/assets/logo_only_circle.png')"
              contain
              max-width="60"
              min-height="60"
              width="100%"
            />
            <base-img
              :src="require('@/assets/logo_only_text.png')"
              contain
              max-width="200"
              min-height="100"
              width="100%"
            />
          </v-row>
          <base-title />

          <base-body>
            <div
              v-for="(t, i) in $t('Footer.rowCompanyText')"
              :key="i"
            >
              {{ t.text }}
            </div>
          </base-body>

          <base-btn
            color="white"
            outlined
            @click="switchToAbout()"
          >
            {{ $t('Footer.btnText') }}
          </base-btn>
        </v-col>

        <!-- Contact -->
        <v-col
          cols="12"
          sm="4"
        >
          <base-info-card :title="$t('Footer.rowTitleContact')" />
          <base-business-contact dense />
        </v-col>

        <!-- Co-founder -->
        <v-col
          cols="12"
          sm="4"
        >
          <base-info-card :title="$t('Footer.rowTitleCoFounder')" />
          <base-body class="pa-2">
            <div
              v-for="(t, i) in $t('Footer.rowCoFounderText')"
              :key="i"
            >
              {{ t.text }}
            </div>
          </base-body>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',
    methods: {
      switchToAbout () {
        return this.$router.replace({ name: 'About' })
      },
    },
  }
</script>
